<script>
export default {};
</script>

<template>
  <div class="container section-padding" id="contact">
    <div class="text title">Contact Form</div>
    <div class="text subtitle">Description</div>
    <div class="content" style="text-align: center">
      <div class="contact-form">
        <input class="form-control" type="text" placeholder="Fullname" />
        <input class="form-control" type="text" placeholder="Email" />
        <input class="form-control" type="text" placeholder="Phone" />
      </div>
      <div style="display: flex; justify-content: center">
        <div class="button dark" >Send</div>
      </div>
    </div>
  </div>
</template>
