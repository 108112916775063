<script>
export default {
  props: {
    feature: Object,
  },
  data() {
    return {
      featureTitle: this.feature.title,
      featureDesc: this.feature.description,
      featureItems: this.feature.items,
    };
  },
};
</script>

<template>
  <div class="container section-padding" id="features">
    <div class="text title">{{ featureTitle }}</div>
    <div class="text subtitle">{{ featureDesc }}</div>
    <div v-for="(item, index) in featureItems" :key="index">
      <div
        class="row content"
        :class="'flex-responsive ' + (index % 2 == 0 ? 'right' : '')"
      >
        <div class="col" :class="'feature-content ' + (index % 2 == 0 ? 'right' : '')"
 style="text-align: left">
          <div class="text large">{{ item.title }}</div>
          <div class="text small">{{ item.description }}</div>
          <a :href="item.button_url" target="_blank">
            <div class="button dark">
              {{ item.button_text }}
            </div>
          </a>
        </div>
        <div class="col">
          <div class="image-container-4-3">
            <img v-bind:src=" 'https://cma.demo-single.creativeworks.id/uploads/' + item.image_url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
