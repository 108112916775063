<script>
export default {
  methods: {
    closeNavbar() {
      const navbarCollapse = this.$refs.navbarNav;
      navbarCollapse.classList.remove('show');
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img src="/assets/images/logo.png" style="height: 4rem;" alt="Logo">
      </a>
      <button class="navbar-toggler" 
      type="button" 
      data-bs-toggle="collapse" 
      data-bs-target="#navbarNav"
      aria-controls="navbarNav" 
      aria-expanded="false" 
      aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav" ref="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="text small text-white" @click="closeNavbar" href="#features">Features</a>
          </li>
          <li class="nav-item">
            <a class="text small text-white"  @click="closeNavbar"  href="#galleries">Galleries</a>
          </li>
          <li class="nav-item">
            <a class="text small text-white"  @click="closeNavbar"  href="#contact">Contact</a>
          </li>
          <li class="nav-item">
            <a class="button light mt-0"  @click="closeNavbar"  href="tel:+62217562929">Call Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

