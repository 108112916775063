<script>
import NavBar from "/src/components/navbars/NavBar.vue";
import SimpleSlider from "/src/components/sliders/SimpleSlider.vue";
import SimpleFeature from "/src/components/features/SimpleFeature.vue";
import SimpleGallery from "/src/components/galleries/SimpleGallery.vue";
import SimpleBanner from "/src/components/banners/SimpleBanner.vue";
import SimpleForm from "/src/components/forms/SimpleForm.vue";
import SimpleFooter from "/src/components/footers/SimpleFooter.vue";
export default {
  components: {
    NavBar,
    SimpleSlider,
    SimpleFeature,
    SimpleGallery,
    SimpleBanner,
    SimpleForm,
    SimpleFooter,
  },
  data() {
    return {
      homepage: {},
    };
  },
  created() {
    // this.$watch(
    //   this.$watch(
    //     () => this.$route.params,
    //     () => {
    this.getHomepageData();
    // },
    // fetch the data when the view is created and the data is
    // already being observed
    // { immediate: true }
    //   )
    // );
  },
  methods: {
    async getHomepageData() {
      await this.axios
        .get("https://cma.demo-single.creativeworks.id/api/home")
        .then((res) => {
          this.homepage = res.data;
        });
    },
  },
};
</script>


<template>
  <NavBar />
  <SimpleSlider :sliders="homepage.slider.items" />
  <SimpleFeature :feature="homepage.feature" />
  <SimpleGallery :gallery="homepage.gallery" />
  <SimpleBanner :banner="homepage.banner" />
  <SimpleForm />
  <SimpleFooter />
</template>
