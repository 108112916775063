<script>
export default {};
</script>

<template>
  <div class="footer section-padding">
    <div class="container py-5">
      <div class="row flex-responsive left">
        <div class="col mt-3">
          <div class="text title" style="text-align: left">Contact Us</div>
          <div class="footer-desc">
            <div class="text large mt-3">Title</div>
            <div class="text small mt-2">Description</div>
          </div>

          <div class="footer-desc mt-3">
            <div class="text large">Title</div>
            <div class="text small mt-2">Description</div>
          </div>

          <div class="footer-desc mt-3">
            <div class="text large">Title</div>
            <div class="text small mt-2">Description</div>
          </div>
        </div>
        <div class="col mt-3">
          <div class="iframe-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.544325834449!2d106.7700425738574!3d-6.191671060659031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f72a1a69d12b%3A0xc656497cd933a4e7!2sCreative%20Works%20Indonesia!5e0!3m2!1sen!2sid!4v1706703893853!5m2!1sen!2sid"
              width="400"
              height="300"
              style="border: 0; border-radius: 0.5rem"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
