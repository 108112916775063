<script>
export default {
  props: {
    banner: Object,
  },
  data() {
    return {
      bannerContent: this.banner,
    };
  },
};
</script>

<template>
  <div class="container section-padding" id="banner">
    <div class="banner" 
    :style="{ backgroundImage: `url(https://cma.demo-single.creativeworks.id/uploads/${bannerContent.image_url})` }" >
      <div class="text title text-white">{{ bannerContent.title }}</div>
      <a :href="bannerContent.button_url" target="_blank">
        <div class="button dark">{{ bannerContent.button_text }}</div>
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>