<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    sliders: Object,
  },
  data() {
    return {
      homeSliders: this.sliders,
    };
  },
  method: {
    init() {
      this.homeSliders = this.sliders;
    },
  },
};
</script>

<template>
  <div class="div">
    <Carousel>
      <Slide v-for="(slide, index) in homeSliders" :key="index">
        <div class="image-container-16-9">
          <!-- <img src="https://dummyimage.com/1980x1080/000/fff" /> -->
          <img :src="'https://cma.demo-single.creativeworks.id/uploads/' + slide.image_url" />
        </div>
        <!-- <div class="carousel__item">{{ slide }}</div> -->
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<style>
.carousel__icon {
  fill: grey;
  background: white;
  border-radius: 0.25rem !important;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  /* padding: 10px; */
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  padding: 1rem;
}

@media only screen and (max-width: 576px) {
  .carousel {
    margin-top: 3.6rem;
  }

  .carousel__prev,
  .carousel__next {
    padding: 0.25rem;
  }
}
</style>
