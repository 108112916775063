<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    gallery: Object,
  },
  data() {
    return {
      galleryTitle: this.gallery.title,
      galleryDesc: this.gallery.description,
      galleryItems: this.gallery.items,
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        576: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>

<template>
  <div class="container section-padding" id="galleries">
    <div class="text title">{{ galleryTitle }}</div>
    <div class="text subtitle">{{ galleryDesc }}</div>
    <div class="content">
      <Carousel
        v-bind="settings"
        :breakpoints="breakpoints"
        :wrap-around="true"
      >
        <Slide v-for="(item, index) in galleryItems" :key="index">
          <div class="card">
            <!-- <div class="card-image-container"> -->
            <div class="image-container-3-2">
              <img :src="'https://cma.demo-single.creativeworks.id/uploads/' + item.image_url" alt="" />
            </div>
            <!-- </div> -->
            <div class="card-content-container p-3">
              <div class="text large">{{ item.title }}</div>
              <div class="text small">{{ item.description }}</div>
              <a :href="item.button_url" target="_blank">
                <div class="button dark">{{ item.button_text }}</div>
              </a>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<style>
.carousel__icon {
    fill: grey;
    background: white;
    border-radius: 100px;
}

.carousel__icon:hover {
    fill: white;
    background: black;
    border-radius: 100px;
    border: 1px white solid
}

.carousel__prev, .carousel__next {
    border: 0;
}
</style>
